<template>
  <div class="list">
    <div class="panelrec">
      <div>
        <!-- <b-table striped hover :items="recRecords" :fields='recFields' @row-clicked="$router.push({ name: 'Recipe', params: { idr: recRecords.id_meal }})"></b-table> -->
        <b-table
          striped
          hover
          :items="searchRecipesArray"
          :fields="searchRecipesFields"
          @row-clicked="routerToRecipe"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Trwa wyszukiwanie...</strong>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>


<script>
// @ is an alias to /src

import { mapState } from "vuex";

export default {
  name: "Search",
  props: {
    rname: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      searchRecipesFields: [
        {
          key: "name",
          label: "Nazwa",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    rname: function () {
      this.$store.dispatch("rc/getSearchRecipes", this.rname);
    },
  },
  computed: {
    ...mapState("rc",["searchRecipesArray"]),
    idr: {
      get() {
        return this.$store.state.rc.idr;
      },
      set(value) {
        this.$store.commit("rc/SET_RECIPE_IDR", value);
      },
    },
  },
  methods: {
    routerToRecipe(val) {
      this.idr = val.id_meal;
      this.$router.push({ name: "Recipe", params: { id: this.idr } });
    },
  },
  created: function () {
    this.$store.dispatch("rc/getSearchRecipes", this.rname);
  },
};
</script>

<style scoped>
</style>